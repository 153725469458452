.bg-lightgray {
    background-color: #D3D3D3;
}

.no-padding{
	padding:0;
}


.software-portfolio .card{
	border-radius:0;
	margin:2px;
	margin-bottom:5vh;
	background-color:#EEEEEE;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
}

.software-portfolio .card:hover{
	cursor:pointer;
	background-color:#DDDDDD;
	transform: scale(1.1, 1.1);
	-moz-transform: scale(1.1, 1.1);
	-webkit-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
}

.icon-cell{
	height: 100%;
	width: 100%;
}

/* #5BC0DE info blue*/