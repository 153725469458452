.App {
  position:absolute;
  top:0;
  left:0;
  width:100%;

  padding:0;
  margin:0;
}

.bg-color-transp{ background-color:rgba(255,255,255,0) !important }

.content-wrapper{
  position:absolute;
	width:100%;
  top:0;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}