.profile-pic{
	width:200px;
	height:200px;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto;
}


.instagram-icon, .facebook-icon, .twitter-icon{
  font-size:40px;
  margin: 0 5px 0 5px;
}

.facebook-icon{
  font-size:35px;
  color:#3b5998;
}

.twitter-icon{
  font-size:35px;
  color:#1DA1F2;
}

.instagram-icon path {
  fill: url(#rg);
}