.sidepanel{
    height: 100%; /* 100% Full-height */
    width: 25vw; /* 0 width - change this with JavaScript */
	max-width:25vw;
    position: fixed; /* Stay in place */
    z-index: 20; /* Stay on top */
    top: 0;
    left: 100%;
    background-color: #111; /* Black*/
    padding-top: 1vh; 
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
    overflow:hidden;
}

.sidepanel-inline{
    width:25vw !important; /* need to be important to not shrink container */
}

/* Position and style the close button (top right corner) */
.sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color:dimgray;
}

.sidepanel .closebtn:focus {
    text-decoration:none;
}

.sidepanel .closebtn:hover{
    color:dimgray;
    text-decoration:none;
}

/* Make space for scrollbar on small height */
@media (max-height: 800px)
{
	.sidepanel{
		overflow-y:auto;
	}
	
	.sidepanel-inline{
        padding-right:15px !important;
	}
}



.screen-dimmer{
	position:fixed;
	top:0;
	left:0;
	height:100%;
	width:100%;
	background-color:rgba(0,0,0,.7);
	z-index:19; /* Behind sidepanel */
}

.bgcolor-transp{
    background-color:rgba(0, 0, 0, 0) !important;
}