.nav-fixed{
	position: fixed !important;
	top:0;
	left:inherit; /* if not inherit navbar will be offset */
	width:100%;
	z-index:10;
	margin-top:0;
}

.bgcolor-transp-6-dark	{ background-color:rgba(0,0,0,.2) }

.only-on-mob{
	display:none;
}
.only-on-desk{
	display:block;
}

.navbar-button{
	padding:0 !important;
}
.navbar-button:active, .navbar-button:visited, .navbar-button:focus{
	text-decoration: none;
}

.btn-scroller{
    color: #007bff !important;
	text-decoration: none;
	user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
	border-radius: .25rem;
	text-align: center;
	vertical-align: middle;
	display:inline-block;
}

.btn-scroller:hover{
	cursor: pointer;
	color: #00438c !important;
}

.follow-icon{
	margin-left:7px;
	margin-right:4vw;
	font-size: 32px;
	float:right;
}

.login-icon{
	margin-top:-3px;
	margin-left:7px;
}